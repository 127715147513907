<template>
  <b-card>
    <b-tabs v-model="tabIndex" pills>
      <b-tab :title="$t('Categories')" active>
        <EntityList
          :config="config"
          :columns="columnsCategories"
          :custom-query="{
            type: 'CATEGORY',
          }"
          authority="General_Category"
          single-edit
        />
      </b-tab>

      <b-tab :title="$t('Sub-Categories')" lazy>
        <EntityList
          :config="config"
          :columns="columnsSubCategories"
          :custom-query="{
            type: 'SUB_CATEGORY',
          }"
          authority="General_Category"
          single-edit
        />
      </b-tab>

      <b-tab :title="$t('Attributes')" lazy>
        <EntityList
          :config="config"
          :columns="columnsAttributes"
          :custom-query="{
            type: 'ATTRIBUTE',
          }"
          authority="General_Category"
          single-edit
        />
      </b-tab>

      <b-tab :title="$t('Sub-Attributes')" lazy>
        <EntityList
          :config="config"
          :columns="columnsSubAttributes"
          :custom-query="{
            type: 'SUB_ATTRIBUTE',
          }"
          authority="General_Category"
          single-edit
        />
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import { BTabs, BTab, BCard } from 'bootstrap-vue'

import EntityList from '@/layouts/entity/EntityList.vue'
import storeModule from '../productCategoryStoreModule'

export default {
  components: {
    BTabs,
    BTab,
    BCard,
    EntityList,
  },
  data() {
    return {
      tabIndex: 0,
    }
  },
  computed: {
    config() {
      return {
        store: storeModule,
        module: 'categories',
        endpoint: 'categories',
        route: 'product-categories',
        title: {
          single:
            (this.tabIndex == 0 && this.$i18n.t('category')) ||
            (this.tabIndex == 1 && this.$i18n.t('Sub-Category')) ||
            (this.tabIndex == 2 && this.$i18n.t('prodAttr')) ||
            (this.tabIndex == 3 && this.$i18n.t('prodSubAttr')),
          plural:
            (this.tabIndex == 0 && this.$i18n.t('Categories')) ||
            (this.tabIndex == 1 && this.$i18n.t('Sub-Categories')) ||
            (this.tabIndex == 2 && this.$i18n.t('Attributes')) ||
            (this.tabIndex == 3 && this.$i18n.t('Sub-Attributes')),
        },
      }
    },

    columnsCategories() {
      return [
        { key: 'id', label: this.$t('id'), sortable: true },
        { key: 'title', label: this.$t('title'), sortable: true },
        { key: 'actions', label: this.$t('actions') },
      ]
    },

    columnsSubCategories() {
      return [
        { key: 'id', label: this.$t('id'), sortable: true },
        { key: 'title', label: this.$t('title'), sortable: true },
        {
          key: 'categoryParent.title',
          label: this.$t('parent-category'),
        },
        { key: 'actions', label: this.$t('actions') },
      ]
    },

    columnsAttributes() {
      return [
        { key: 'id', label: this.$t('id'), sortable: true },
        { key: 'title', label: this.$t('title'), sortable: true },
        {
          key: 'subCategoryParent.title',
          label: this.$t('parent-subcategory'),
        },
        {
          key: 'categoryParent.title',
          label: this.$t('parent-category'),
        },
        { key: 'actions', label: this.$t('actions') },
      ]
    },

    columnsSubAttributes() {
      return [
        { key: 'id', label: this.$t('id'), sortable: true },
        { key: 'title', label: this.$t('title'), sortable: true },
        {
          key: 'attributeParent.title',
          label: this.$t('parent-attribute'),
        },
        {
          key: 'subCategoryParent.title',
          label: this.$t('parent-subcategory'),
        },
        {
          key: 'categoryParent.title',
          label: this.$t('parent-category'),
        },
        { key: 'actions', label: this.$t('actions') },
      ]
    },
  },
}
</script>
