var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-tabs',{attrs:{"pills":""},model:{value:(_vm.tabIndex),callback:function ($$v) {_vm.tabIndex=$$v},expression:"tabIndex"}},[_c('b-tab',{attrs:{"title":_vm.$t('Categories'),"active":""}},[_c('EntityList',{attrs:{"config":_vm.config,"columns":_vm.columnsCategories,"custom-query":{
          type: 'CATEGORY',
        },"authority":"General_Category","single-edit":""}})],1),_c('b-tab',{attrs:{"title":_vm.$t('Sub-Categories'),"lazy":""}},[_c('EntityList',{attrs:{"config":_vm.config,"columns":_vm.columnsSubCategories,"custom-query":{
          type: 'SUB_CATEGORY',
        },"authority":"General_Category","single-edit":""}})],1),_c('b-tab',{attrs:{"title":_vm.$t('Attributes'),"lazy":""}},[_c('EntityList',{attrs:{"config":_vm.config,"columns":_vm.columnsAttributes,"custom-query":{
          type: 'ATTRIBUTE',
        },"authority":"General_Category","single-edit":""}})],1),_c('b-tab',{attrs:{"title":_vm.$t('Sub-Attributes'),"lazy":""}},[_c('EntityList',{attrs:{"config":_vm.config,"columns":_vm.columnsSubAttributes,"custom-query":{
          type: 'SUB_ATTRIBUTE',
        },"authority":"General_Category","single-edit":""}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }